import { required, minLength, maxLength } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            key: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100),
            },
            value: {
                required,
                minLength: minLength(2),
            },
            url: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(150),
            },
            description:{
                
            }
        },
    }
}

export default { getValidations };