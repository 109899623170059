<template>
  <div class="animated fadeIn">
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="6" md="6" xs="12" offset-sm="3">
          <b-card>
            <div slot="header">
              <strong>
                <i class="fa fa-term"></i>
                {{ term.id == 0 ? $t("newTerm") : $t("edit") }}</strong
              >
            </div>

            <b-row>
              <b-col sm="12">
                <ValidationableTextBox
                  prename="term"
                  id="key"
                  @onLostFocus="onNameLostFocus()"
                  icon="fa fa-term"
                  v-model="$v.form.key.$model"
                  :form="$v.form.key"
                />
              </b-col>
              <b-col sm="12">
                <ValidationableTextBox
                  prename="term"
                  id="value"
                  icon="fa fa-term"
                  v-model="$v.form.value.$model"
                  :form="$v.form.value"
                />
              </b-col>
              <b-col sm="12">
                <ValidationableTextBox
                  prename="term"
                  id="url"
                  @keydown="(e) => onUrlKeyDown(e)"
                  icon="fa fa-link"
                  v-model="$v.form.url.$model"
                  :form="$v.form.url"
                />
              </b-col>
            </b-row>
            <div class="row">
              <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600" style="padding-left:10px">
                {{ $t("description") }}
              </h6>
              <div class="col-sm-12">
                <TextEditor
                  :content="form.description"
                  @input="(val) => (form.description = val)"
                />
              </div>
            </div>
            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import http from "../../shared/http";
import stringHelper from "../../shared/string-helper";
import termValidator from "../../validations/term-validator";
import toast from "../../shared/toast-helper";
import CheckBox from "../../widgets/form/CustomCheckBox";
import BrandButton from "../../widgets/form/CustomBrandButton";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import { validationMixin } from "vuelidate";

import TextEditor from "../../widgets/form/TextEditor";
export default {
  name: "termAdd",
  components: {
    ValidationableTextBox,
    CheckBox,
    BrandButton,
    TextEditor,
  },
  mixins: [validationMixin],
  validations: termValidator.getValidations(),
  methods: {
    onNameLostFocus() {
      if (this.form.url == "") {
        this.form.url = stringHelper.standardUrl(this.form.key);
      }
    },
    onUrlKeyDown(e) {
      if (e.shiftKey || e.altKey || e.ctrlKey) e.returnValue = false;
      else e.returnValue = stringHelper.isValidNumChar(e.key);
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      let model = { ...this.term, ...this.form };
      let path = model.id > 0 ? "terms/update" : "terms/add";
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          this.$router.push({ path: "/term-list" });
        }
      });
    },
    getUpdateForm(termId) {
      let path = `terms/getbyid?id=${termId}`;
      http.get(path).then((term) => {
        if (term) {
          this.term = term;
          this.form.key = term.key;
          this.form.url = term.url;
          this.form.value = term.value;
          this.form.description = term.description;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  data() {
    return {
      form: {
        key: "",
        url: "",
        value: "",
        description: "",
      },
      term: {
        id: 0,
        key: "",
        url: "",
        value: "",
        description: "",
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  watch: {
    form: {
      handler(obj) {
        let standardUrl = stringHelper.standardUrl(obj.url);
        obj.url = standardUrl;
      },
      deep: true,
    },
  },
  created() {
    let termId = this.$route.params.id ? this.$route.params.id : 0;
    if (termId > 0) this.getUpdateForm(termId);
  },
};
</script>
